import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import ConvertKitForm from "convertkit-react";

import Layout from "../components/Layout";
import BlogRoll from "../components/BlogRollHome";

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div style={{ width: "100vw" }}>
    <div
      style={{
        width: "100vw",
        textAlign: "center",
      }}
    >
      <h1 className="title" style={{ paddingTop: "1em" }}>
        {mainpitch.title}
      </h1>
      {/* <h3 className="subtitle">{mainpitch.description}</h3> */}
      <Link to="/make-money/compound-interest-investments/">
        <button className="button top-of-home-button is-medium">
          &gt; &gt; Master Your Wealth
        </button>
      </Link>
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="content">
            <h3 className="has-text-centered">FEATURED ON</h3>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexWrap: "wrap",
                gap: "2em",
                justifyContent: "space-evenly",
                backgroundColor: "#f5f5f5",
              }}
            >
              <div
                style={{
                  width: "200px",
                  height: "40px",
                  backgroundSize: "contain",
                  backgroundImage: `url('/img/go-banking-rates-logo.png')`,
                }}
              ></div>
              <div
                style={{
                  width: "158px",
                  height: "40px",
                  backgroundSize: "contain",
                  backgroundImage: `url('/img/apartment-therapy-logo.png')`,
                }}
              ></div>
              <div
                style={{
                  width: "175px",
                  height: "40px",
                  backgroundSize: "contain",
                  backgroundImage: `url('/img/credit-sesame-logo.png')`,
                }}
              ></div>
              <div
                style={{
                  width: "88px",
                  height: "40px",
                  backgroundSize: "contain",
                  backgroundImage: `url('/img/side-hustle-nation-logo.png')`,
                }}
              ></div>
              <div
                style={{
                  width: "100px",
                  height: "40px",
                  backgroundSize: "contain",
                  backgroundImage: `url('/img/yahoo-logo.png')`,
                }}
              ></div>
            </div>
            </div>
        </div>
      </div>
      <div
        style={{
          width: "100vw",
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          gap: "2em",
          justifyContent: "center",
        }}
      >
        <Link to="/tags/make-money">
          <div
            className="homepage-category-button"
            style={{
              backgroundImage: `url('/img/make.png')`,
            }}
          ></div>
        </Link>
        <Link to="/tags/budget">
          <div
            className="homepage-category-button"
            style={{
              backgroundImage: `url('/img/budget.png')`,
            }}
          ></div>
        </Link>
      </div>
      <div
        style={{
          width: "100vw",
          textAlign: "center",
          display: "flex",
          flexWrap: "wrap",
          gap: "2em",
          justifyContent: "center",
          paddingTop: "2em",
        }}
      >
        <Link to="/tags/save-money">
          <div
            className="homepage-category-button"
            style={{
              backgroundImage: `url('/img/save.png')`,
            }}
          ></div>
        </Link>
        <Link to="/tags/invest">
          <div
            className="homepage-category-button"
            style={{
              backgroundImage: `url('/img/invest.png')`,
            }}
          ></div>
        </Link>
      </div>
    </div>
    <section
      className="section section--gradient home-page-section"
      style={{
        paddingTop: "2rem",
      }}
    >
      <div className="container">
        <div
          className="section"
          style={{
            paddingTop: "0px",
          }}
        >
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="column is-12" style={{
                  paddingTop: "2rem",
                }}>
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest Money Reads
                  </h3>
                  <BlogRoll />
                  <div className="column is-12 has-text-centered">
                    <Link className="btn" to="/blog">
                      Read more
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ConvertKitForm
    formId={5707393}
    template="mills"
    emailPlaceholder="Email Address"
    submitText="Get My Updates"
    format="modal"
    headingText="Join the Newsletter"
    hideName={true}
    buttonBackground="#008307"
    className="ck-fm"
    >
      <p>Subscribe to get our latest content by email.</p>
    </ConvertKitForm>
  </div>
);

IndexPageTemplate.propTypes = {
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        // image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        subheading
        mainpitch {
          title
          description
        }
        description
      }
    }
  }
`;
